/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import {
  setAccessTokenForVetericalAnalyticsClient,
  setAccessTokenCallbackForVetericalAnalyticsClient,
} from '../client/accessToken.js'
import { setRefreshTokenForVetericalAnalyticsClient } from '../client/refreshToken.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()

    setAccessTokenCallbackForVetericalAnalyticsClient(null)

    setAccessTokenForVetericalAnalyticsClient(null)
    setRefreshTokenForVetericalAnalyticsClient(null)
  }
}
