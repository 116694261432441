/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format/index.js'
import table from '@stiloso/styles/table.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

export const paths = ['/appointments']

const fetchAppointmentsPageData = cache(() =>
  get({ url: '/api/pages/appointments' }),
)

const AppointmentsPage = () => {
  const navigate = useNavigate()
  let clinics = use(fetchAppointmentsPageData())

  return (
    <Layout>
      <div css={{ padding: 16 }}>
        <h1>{clinics.length} clinics</h1>
        <table css={table}>
          <thead>
            <tr>
              <th>name</th>
              <th>signed up</th>
              <th>appointments</th>
            </tr>
          </thead>
          <tbody>
            {clinics?.map(clinic => (
              <tr
                key={clinic._id}
                onClick={() => navigate(`/clinics/${clinic._id}`)}
              >
                <td>{clinic.name || clinic._id}</td>
                <td>{format(clinic.creationTime, 'D MMMM YYYY')}</td>
                <td>{clinic.appointmentsCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default AppointmentsPage
