/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconAlarm from '@stiloso/icons/IconAlarm.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'
import IconRssFeed from '@stiloso/icons/IconRssFeed.js'
import IconUsers from '@stiloso/icons/IconUsers.js'

const Layout = ({ children }) => {
  const { t } = useTranslation()

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  const drawerButtons = [
    {
      label: 'Appointments',
      to: '/appointments',
      Icon: IconAlarm,
    },
    {
      label: 'Clinics',
      to: '/clinics',
      Icon: IconRssFeed,
    },
    {
      label: 'Users',
      to: '/users',
      Icon: IconUsers,
    },
  ].filter(_ => _)

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: `
          'drawer children'
        `,
      }}
    >
      <Drawer>
        <DrawerTitle>{t('Veterical')}</DrawerTitle>
        {drawerButtons.map(({ label, to, Icon }) => (
          <DrawerButton
            key={to}
            label={t(label)}
            to={to}
            Icon={Icon}
            selected={pathname.endsWith(to)}
          />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout
