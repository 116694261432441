/* Copyright 2013 - 2024 Waiterio LLC */
import {
  setAccessTokenForVetericalAnalyticsClient,
  setAccessTokenCallbackForVetericalAnalyticsClient,
} from '../client/accessToken.js'
import { setRefreshTokenForVetericalAnalyticsClient } from '../client/refreshToken.js'
import getAccessToken from './getAccessToken.js'
import getRefreshToken from './getRefreshToken.js'
import setAccessToken from './setAccessToken.js'

export default async function rehydrateSession() {
  const accessToken = getAccessToken()
  const refreshToken = getRefreshToken()

  setAccessTokenForVetericalAnalyticsClient(accessToken)
  setRefreshTokenForVetericalAnalyticsClient(refreshToken)
  setAccessTokenCallbackForVetericalAnalyticsClient(setAccessToken)
}
