/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use } from 'react'
import { css, jsx } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format/index.js'
import getCountryNameByCode from '@monorepo/shared/getCountryNameByCode.js'
import table from '@stiloso/styles/table.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

export const paths = ['/users']

const fetchUsersPageData = cache(() => get({ url: '/api/pages/users' }))

const UsersPage = () => {
  const navigate = useNavigate()
  let users = use(fetchUsersPageData())
  users = users.sort((a, b) => (a.creationTime > b.creationTime ? -1 : 1))

  return (
    <Layout>
      <div css={{ padding: 16 }}>
        <h1>{users.length} Users</h1>
        <table css={table}>
          <thead>
            <tr>
              <th>email</th>
              <th>creation</th>
              <th>language</th>
              <th>country</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(user => (
              <tr key={user._id} onClick={() => navigate(`/users/${user._id}`)}>
                <td>{user.email}</td>
                <td>{format(user.creationTime, 'D MMMM YYYY')}</td>
                <td>{user.language}</td>
                <td>
                  {user.country ? getCountryNameByCode(user.country) : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default UsersPage
