/* Copyright 2013 - 2024 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getVetericalAnalyticsUrl() {
  let url = 'https://analytics.veterical.com'

  if (
    IS_BROWSER &&
    window.VETERICAL_ANALYTICS_URL === '{{{VETERICAL_ANALYTICS_URL}}}'
  ) {
    delete window.VETERICAL_ANALYTICS_URL
  }

  if (
    IS_NODE &&
    process.env.VETERICAL_ANALYTICS_URL === '{{{VETERICAL_ANALYTICS_URL}}}'
  ) {
    delete process.env.VETERICAL_ANALYTICS_URL
  }

  if (IS_BROWSER && window.VETERICAL_ANALYTICS_URL) {
    url = `${window.VETERICAL_ANALYTICS_URL}`
  } else if (IS_NODE && process.env.VETERICAL_ANALYTICS_URL) {
    url = process.env.VETERICAL_ANALYTICS_URL
  } else if (IS_STAGING) {
    url = 'https://analytics.veterical-staging.com'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://analytics.veterical-staging.com';
    // url = 'http://192.168.1.64:24700';
    url = 'http://localhost:24700'
  }

  // if (IS_STAGING || IS_DEVELOPMENT) {
  //   console.debug('veterical-analytics.url = ' + url)
  // }

  return url
}
