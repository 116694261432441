/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from '@veterical/shared/GlobalStyles.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import Spinner from '@stiloso/components/Spinner.js'
import SessionRehydrator from './session/SessionRehydrator.js'
import Routes from './Routes.js'

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <BrowserRouter>
      <SessionRehydrator>
        <Suspense fallback={<Spinner />}>
          <ScrollToTop />
          <GlobalStyles />
          <Routes />
          <StageBadge />
        </Suspense>
      </SessionRehydrator>
    </BrowserRouter>
  </ErrorBoundary>,
)
