/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { cache, use } from 'react'
import { css, jsx } from '@emotion/react'
import { Bar } from 'react-chartjs-2'
import { useNavigate, useParams } from 'react-router-dom'
import addDays from 'date-fns/add_days/index.js'
import differenceInDays from 'date-fns/difference_in_days/index.js'
import format from 'date-fns/format/index.js'
import 'chartjs-plugin-datalabels'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

let clickableCard = css([
  clickable,
  card,
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginRight: 16,
  },
])

export const paths = ['/clinics/:clinicId']

const fetchClinicPageData = cache(clinicId =>
  get({ url: `/api/pages/clinics/${clinicId}` }),
)

const ClinicPage = () => {
  const navigate = useNavigate()
  const { clinicId } = useParams()
  let data = use(fetchClinicPageData(clinicId))
  // console.log({ data })
  let {
    appointments,
    appointmentsCount,
    clientsCount,
    proceduresCount,
    clinic,
    users,
  } = data || {}

  // console.log({ appointments })

  const startTime = Math.min(
    ...appointments.map(appointment =>
      new Date(appointment.creationTime).getTime(),
    ),
  )

  const days = differenceInDays(new Date(), startTime) + 1

  const day2appointmentsCount = [...Array(days || 0).keys()].reduce(
    (day2mealsCount, i) =>
      Object.assign(day2mealsCount, {
        [format(addDays(startTime, i), 'YYYY-MM-DD')]: 0,
      }),
    {},
  )

  appointments.forEach(appointment => {
    const day = format(appointment.creationTime, 'YYYY-MM-DD')

    day2appointmentsCount[day] += 1
  })

  let labels = Object.keys(day2appointmentsCount)
  let dataAppointments = Object.keys(day2appointmentsCount).map(
    day => day2appointmentsCount[day],
  )

  let chart = {
    data: {
      labels,
      datasets: [
        {
          label: 'appointments',
          data: dataAppointments,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      layout: {
        padding: {
          top: 20,
        },
      },
      plugins: {
        datalabels: {
          color: 'rgba(98, 98, 98, 1)',
          align: 'top',
          anchor: 'end',
          display: context => context.dataset.data[context.dataIndex] !== 0,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'D MMM',
              },
              unitStepSize: 1,
            },
            offset: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: false,
              max: Math.max(...dataAppointments),
            },
          },
        ],
      },
    },
  }

  return (
    <Layout>
      <div css={{ padding: 16 }}>
        <h1>{clinic.name || clinic._id}</h1>

        <div css={{ padding: 16 }}>
          <div>{format(clinic.creationTime, 'D MMMM YYYY')}</div>
        </div>

        <div css={{ padding: 16, paddingBottom: 0 }}>
          <h2>{appointmentsCount} appointments</h2>
        </div>

        <Bar height={window.innerWidth < 576 ? 200 : 80} {...chart} />

        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{clientsCount} clients</h2>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{proceduresCount} procedures</h2>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>{users.length} users</h2>
          <div css={{ display: 'flex' }}>
            {users.map(user => (
              <div
                key={user._id}
                css={clickableCard}
                onClick={() => navigate(`/users/${user._id}`)}
              >
                <div>
                  {user.firstName} {user.lastName}
                </div>
                <div>{user.email}</div>
                <div>{user?.roles.join(', ')}</div>
              </div>
            ))}
          </div>
        </div>
        <div css={{ padding: 16 }}>
          <h2 css={{ paddingBottom: 8 }}>organization</h2>
          <div css={{ display: 'flex' }}>
            <div
              css={clickableCard}
              onClick={() =>
                navigate(`/organizations/${clinic.organizationId}`)
              }
            >
              <div>organization {clinic.organizationId}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ClinicPage
