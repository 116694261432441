/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint-disable camelcase */
/** @jsx jsx */
import React, { cache, use } from 'react'
import format from 'date-fns/format/index.js'
import { css, jsx } from '@emotion/react'
import { useNavigate, useParams } from 'react-router-dom'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import waiterioVetericalOrganizationId from '@veterical/shared/waiterioVetericalOrganizationId.js'
import delete_ from '../client/delete_.js'
import get from '../client/get.js'
import Layout from '../components/Layout.js'

let clickableCard = css([
  clickable,
  card,
  {
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    marginRight: 16,
  },
])

export const paths = ['/organizations/:organizationId']

const fetchOrganizationPageData = cache(organizationId =>
  get({ url: `/api/pages/organizations/${organizationId}` }),
)

const DANGER_deleteOrganizationPageData = cache(organizationId =>
  delete_({
    url: `/api/pages/organizations/${organizationId}/DANGER_deleteAllOrganizationData`,
  }),
)

const OrganizationPage = () => {
  const navigate = useNavigate()
  const { organizationId } = useParams()
  let data = use(fetchOrganizationPageData(organizationId))
  console.log(data)
  let { appointments, appointmentsCount, clinics, organization, roles, users } =
    data || {}

  const DANGER_deleteAllOrganizationData = async () => {
    await DANGER_deleteOrganizationPageData(organizationId)
    window.location = '/users'
  }

  return (
    <Layout>
      <div css={{ padding: 16 }}>
        {organization && (
          <>
            <h1>{organization.name || organization._id} organization</h1>
            <div css={{ padding: 16 }}>
              <h2 css={{ paddingBottom: 8 }}>
                {appointmentsCount} appointments
              </h2>
              <div css={{ display: 'flex', flexWrap: 'wrap' }}>
                {appointments.map(appointment => (
                  <div
                    key={appointment._id}
                    css={[
                      clickableCard,
                      { maxWidth: 240, padding: 8, marginBottom: 16 },
                    ]}
                    onClick={() => navigate(`/appointments/${appointment._id}`)}
                    title={appointment.content}
                  >
                    <div css={{ paddingBottom: 8 }}>
                      {format(appointment.creationTime, 'D MMMM YYYY')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div css={{ padding: 16 }}>
              <h2 css={{ paddingBottom: 8 }}>clinics</h2>
              <div css={{ display: 'flex' }}>
                {clinics.map(clinic => (
                  <div
                    key={clinic._id}
                    css={clickableCard}
                    onClick={() => navigate(`/clinics/${clinic._id}`)}
                  >
                    <div>{clinic.name || clinic._id}</div>
                  </div>
                ))}
              </div>
            </div>
            <div css={{ padding: 16 }}>
              <h2 css={{ paddingBottom: 8 }}>users</h2>
              <div css={{ display: 'flex' }}>
                {users.map(user => (
                  <div
                    key={user._id}
                    css={clickableCard}
                    onClick={() => navigate(`/users/${user._id}`)}
                  >
                    <div>{user.email}</div>
                    <div>
                      {roles
                        .filter(role => user._id === role.userId)
                        .map(role => role.type)
                        .join(', ')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {organizationId !== waiterioVetericalOrganizationId && (
              <button
                css={{
                  background: '#d9534f',
                  borderRadius: 2,
                  color: 'white',
                  cursor: 'pointer',
                  fontSize: 18,
                  lineHeight: '48px',
                  marginTop: 48,
                  padding: '0 16px',
                  textTransform: 'uppercase',
                  ':hover': { background: '#a7717c' },
                }}
                onClick={DANGER_deleteAllOrganizationData}
                type="button"
              >
                DANGER Delete all organization data
              </button>
            )}
          </>
        )}
      </div>
    </Layout>
  )
}

export default OrganizationPage
