/* Copyright 2013 - 2024 Waiterio LLC */
import getVetericalAnalyticsUrl from '@monorepo/env/getVetericalAnalyticsUrl.js'

export default (email, password) =>
  fetch(getVetericalAnalyticsUrl() + '/api/login', {
    method: 'POST',
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  }).then(response => {
    if (!response.ok) {
      return response.json().then(error => {
        throw error
      })
    } else {
      return response.json()
    }
  })
